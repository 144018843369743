// Bootstrap library.
@import 'bootstrap.less';
@import 'bootswatch.less';
// Base-theme overrides.
@import 'overrides.less';

// Theme specific.
@import 'header.less';
@import 'content.less';
@import 'footer.less';

// Fonts
@import '../font-awesome/less/font-awesome.less';
@import url(//fonts.googleapis.com/css?family=Coming+Soon);


// Mixins

.cover () {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}