// Insert your styling here.
//dropdown
ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}
ul.nav li.dropdown ul.dropdown-menu {
  margin-top: 0;
  @media (max-width: @grid-float-breakpoint){
	  position:relative;
	  width: 100%;
	  margin-bottom: 15px;
	  -webkit-box-shadow: none;
	  -moz-box-shadow: none;
	  box-shadow: none;
  }
  li a {
    &:hover {
      background-color: @brand-primary;
      color: #fff;
    }
  }
}
.navbar-header {
	float: none;
	text-align:center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.navbar-collapse {
  border-top: 1px solid #f1f1f1;
  padding-top: 20px; 
}

.logo img {
	max-width: 150px !important;
	height: auto;
}
.navbar-nav > li > span {
	padding: 8px 15px;
	line-height: 1;
	display:block;
	color: #a6a6a6;
  text-transform: uppercase;
  font-size: 12px;
}
.navbar-default .navbar-nav > li > a {
  text-transform: uppercase;
  padding: 8px 15px;
  letter-spacing: 0.5px;
  line-height: 1;
  font-size: 12px;
  @media (min-width: @grid-float-breakpoint) {
    border-radius: 20px;
    -moz-outline-radius: 20px;
    margin: 0 10px;
    &:hover, &.active {
      background-color: transparent;
      color: @brand-primary;
    }
  }
}
.navbar-default .navbar-nav > .active:first-of-type > a, .navbar-default .navbar-nav > .active:first-of-type > a:hover, .navbar-default .navbar-nav > .active:first-of-type > a:focus {
  @media (min-width: @grid-float-breakpoint) {
    border: 1px solid @brand-primary;
    border-radius: 20px;
  }
  font-weight: 600;
}

.region.region-navigation {
	.pull-right();
	@media (min-width: @grid-float-breakpoint) {
		position: relative;
	}
	.language-switcher-locale-url {
		list-style: none;
		li {
			list-style: none;
			display: inline;
			a {
				display:inline-block;
				height: 30px;
				width: 30px;
				line-height: 30px;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
				border-radius: 50%;
				&:hover, &.active {
					background: transparent;
          border: 1px solid @brand-primary;
					color: @brand-primary;
				}
			}
		}
	}
}