// Insert your styling here.
.footer {
	background: #47712a;
	color: #fff;
  font-weight: 300;
	margin-top: 0;
  padding-top: 60px;
  padding-bottom: 60px;
  a {
    color: #8bb56f;
    text-decoration: none;
  }
	.view-footer {
		.row:first-child {
			margin-bottom: 30px;
		}
	}
}