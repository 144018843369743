// Insert your styling here.
img {
	max-width: 100%;
	height: auto;
}
.flexslider {
  margin: 0;
  border: 0 none;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.front .page-header {
	display: none;
}

.not-front #block-system-main {
  padding-bottom: 30px;
}
.page-node-45 {
  .tabs--primary {
    display: none;
  }
  #block-system-main {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.container-fluid .jumbotron {
  padding: 0;
  margin: 0;
}
.btn-info,
.btn-success,
.btn-primary {
  &:hover {
    border-color: @brand-primary;
    color: @brand-primary;
  }
}
.btn-success {
  &:hover {
    a {
      color: @brand-primary;
    }
  }
  a {
    display:block;
    color: #bec4c2;
    text-decoration: none;
  }
}
.field.field-name-node-link {
  a {
    color: #bec4c2;
    text-decoration: none;
    border: 1px solid #bec4c2;
    border-radius: 20px;
    padding: 8px 12px;
    min-width: 130px;
    font-size: 12px;
    display:inline-block;
    text-transform: uppercase;
    &:hover {
      border-color: @brand-primary;
      color: @brand-primary;
    }
  }
}

.block-title {
	text-align: center;
	text-transform: uppercase;
  font-weight: bold;
  color: #707070;
}

.not-front #page-header {
  min-height: 170px;
  .row();
  background: url('../images/ekss-sidbanner.jpg') no-repeat;
  .cover();
  img {
    width: 100%;
    height: auto;
  }
}
.not-front .page-header {
  position: relative;
  top: -40px;
  border-bottom: 0 none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 600;
  text-shadow: 0px 0px 2px rgba(150, 150, 150, 1);
}


.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
}

.block h2 {
	text-transform: uppercase;
  font-weight: bold;
  color: #707070;
}
.btn {
  font-size: 12px;
  text-transform: uppercase;
	border-radius: 20px;
	min-width: 130px;
}
.front .view.view-services {
	padding-top: 130px;
	padding-bottom: 100px;
	div[class^='col'], div[class*=' col'] {
    margin-bottom: 30px;
		text-align:center;
		a {
			text-decoration: none;
		}
    .views-field.views-field-title-field {
      color: #313131;
      a {
        color: #313131;
      }
    }
		&:hover {
			.views-field.views-field-field-teaser-image,
			.field.field-name-field-teaser-image {
				background-color: @brand-primary;
				&:before {
					content: ' ';
					position: absolute;
					width: 0;
					height: 0;
					left: -1px;
					top: 99px;
					border: 7px solid;
					border-color: #dadada transparent transparent #dadada;
				}
				&:after {
					content: ' ';
					position: absolute;
					width: 0;
					height: 0;
					left: 0px;
					top: 98px;
					border: 6px solid;
					border-color: @brand-primary transparent transparent @brand-primary;
				}
			}
		}
	}
	.views-field.views-field-field-teaser-image,
	.field.field-name-field-teaser-image {
    a {
      display: inline-block;
	  vertical-align:middle;
    }
		position: relative;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		background-color: #fff;
		border: 1px solid #dadada;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		&:before {
			content: ' ';
			position: absolute;
			width: 0;
			height: 0;
			left: -1px;
			top: 99px;
			border: 7px solid;
			border-color: #dadada transparent transparent #dadada;
		}
		&:after{
			content: ' ';
			position: absolute;
			width: 0;
			height: 0;
			left: 0px;
			top: 98px;
			border: 6px solid;
			border-color: #fff transparent transparent #fff;
		}
	}
}

/* Butik block */
#block-views-store-block {
	padding-top: 100px;
	padding-bottom: 100px;
	color: #fff;
	background: url('../images/store-bg.jpg') no-repeat;
	.cover();
  .field.field-name-body {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    color: #fff;
    margin-bottom: 30px;
  }
	.field.field-name-field-images {
		text-align:center;
		.field-slideshow {
			margin: auto;
		}
		img {
			border-radius: 50%;
			border: 10px solid rgba(71,112,42,0.4);
		}
	}
	table {
    font-size: 16px;
    font-style:italic;
		tr {
			td {
				border: none;
				padding: 0;
        line-height: 2.3;
				&:nth-child(1) {
					&:after {
						content: '';
						display:block;
						height: 1px;
						margin-top: 5px;
						/*background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#fff), to(transparent));
						background-image: -webkit-linear-gradient(left, #fff, transparent);
						background-image: -moz-linear-gradient(left, #fff, transparent);
						background-image: -o-linear-gradient(left, #fff, transparent);*/
						background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 82%, rgba(255,255,255,0) 100%); /* FF3.6+ */
						background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,1)), color-stop(82%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
						background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
						background: -o-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
						background: -ms-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,0) 100%); /* IE10+ */
						background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,0) 100%); /* W3C */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
					}
				}
			}
			&:last-child {
				td:after {
					content: none;
				}
			}
		}
	}
}



/* Block About Us */
#block-views-about-block {
	background: #81a568;
	color: #fff;
	padding-top: 80px;
	padding-bottom: 80px;
	.field.field-name-field-info-text {
		text-transform: uppercase;
    padding: 0 20px;
    display: inline-block;
    text-align: center;
    font-size: 13px;
	}
	.field.field-name-title-field h2 {
		margin-top: 0;
    display: inline-block;
    color: #fff;
    &:after {
      content: "";
			background: url('../images/Info-32.png') no-repeat;
			width: 32px;
			height: 32px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
    }
	}
  .field.field-name-body {
    font-style: italic;
    padding-top: 15px;
    line-height: 1.6;
  }
	.field.field-name-field-teaser-image {
		text-align:center;
		.field-item {
			margin: auto;
		}
		img {
			border-radius: 50%;
			border: 10px solid rgba(71,112,42,0.4);
		}
	}
	.field.field-name-field-links {
		margin-top: 30px;
		.field-item {
			display: inline-block;
			margin-right: 15px;
		}
	}
}


/* Contact form */
.view.view-contact {
	margin-top: 80px;
  margin-bottom: 80px;
	.view-header {
		text-align: center;
		text-transform: uppercase;
    font-size: 13px;
	}
	h2 {
		margin-top: 0;
		text-align: center;
		text-transform: uppercase;
		&:after {
			content: "";
			background: url('../images/Email-32.png') no-repeat;
			width: 32px;
			height: 32px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-top: 15px;
		}
	}
}
#contact-form-entityform-edit-form {
	max-width: 660px;
	margin-left: auto;
	margin-right: auto;
  .form-group {
    margin-bottom: 5px;
  }

	.form-type-textfield {
		width: 100%;
		display: inline-block;
		.form-inline();
		border-bottom: 1px solid #dcdcdc;
		.form-control {
      background-color: transparent;
			border: 0 none;
			box-shadow: none;
      font-family: 'Coming Soon', cursive;
		}
		label {
      color: #aeaeae;
			text-transform: uppercase;
			&:after {
				content: ':';
				position: relative;
				left: 0px;
			}
		}
	}
	.form-type-textarea {
    margin-top: 15px;
    margin-bottom: 20px;
		.grippie {
			display: none;
		}
    .form-control {
      background-color: transparent;
      font-family: 'Coming Soon', cursive;
    }
		label {
      color: #aeaeae;
			text-transform: uppercase;
			&:after {
				content: ':';
				position: relative;
				left: 0px;
			}
		}
	}
	.form-actions {
		text-align:right;
	}
}


#block-views-references-block {
	padding-top: 80px;
	padding-bottom: 80px;
	background: #f9f9f9;
	.view.view-references {
		.row {

      &:first-child {
        margin-top: 30px;
      }
			margin-bottom: 30px;
			> div[class^='col'], div[class*=' col'] {
        > div {
          max-width: 360px;
          margin-bottom: 15px;
          margin-left: auto;
          margin-right: auto;
        }

				.field.field-name-field-type {
					display: inline-block;
					font-weight: bold;
					text-transform: uppercase;
					color: @brand-primary;
				}
        .field.field-name-body {

        }
				.field.field-name-title-field {
					display: inline-block;
					font-style: italic;
          color: #313131;
          font-size: 18px;
					.field-item {
						&:before {
							content: "|";
							margin: 0 5px;
							color: #d9d9d9;
						}
					}
				}
			}
		}
		.view-header {
			text-align: center;
			text-transform: uppercase;
      font-size: 13px;
		}
		.entity.entity-bean.bean-text-block {
			h2 {
				margin-top: 0;
				text-align: center;
				text-transform: uppercase;
				&:after {
					content: "";
					background: url('../images/Opened-Folder-32.png') no-repeat;
					width: 32px;
					height: 32px;
					display: block;
					margin-left: auto;
					margin-right: auto;
					margin-top: 15px;
				}
			}
		}
		.field.field-name-field-links {
			text-align: center;
		}
	}
}
#block-views-references-block-2 {
	.view.view-references {
		.row {

      &:first-child {
        margin-top: 30px;
      }
			margin-bottom: 30px;
			> div[class^='col'], div[class*=' col'] {
        > div {
          max-width: 360px;
          margin-bottom: 15px;
          margin-left: auto;
          margin-right: auto;
        }

				.field.field-name-field-type {
					display: inline-block;
					font-weight: bold;
					text-transform: uppercase;
					color: @brand-primary;
				}
				.field.field-name-title-field {
					display: inline-block;
					font-style: italic;
          color: #313131;
          font-size: 18px;
					.field-item {
						&:before {
							content: "|";
							margin: 0 5px;
							color: #d9d9d9;
						}
					}
				}
			}
		}
		.view-header {
			text-align: center;
			text-transform: uppercase;
		}
		.entity.entity-bean.bean-text-block {
			h2 {
				margin-top: 0;
				text-align: center;
				text-transform: uppercase;
				&:after {
					content: "";
					background: url('../images/Opened-Folder-32.png') no-repeat;
					width: 32px;
					height: 32px;
					display: block;
					margin-left: auto;
					margin-right: auto;
					margin-top: 15px;
				}
			}
		}
		.field.field-name-field-links {
			text-align: center;
		}
	}
}


/* Cleaning services */

#block-views-services-block-1 {
  margin-bottom: 80px;
	.view-services {
		padding-top: 30px;
		.view-content {
			min-height: 700px;
			clear: both;
			position: relative;
			@media (max-width: 768px) {
				height: auto !important;
			}
			@media (min-width: 768px) {
				padding-top: 90px;
			}
			.views-row {
				&.views-row-odd {
					clear: both;
				}
				.showtext {
					width: 100%;
					max-width: 100%;
					@media (min-width: 768px) {
						width: 25%;
						text-align: right;
						padding-right: 30px;
					}
					color: #efefef;
					line-height: 13px;
					cursor: pointer;
					&.active {
						a {
							color: #618846;
              font-weight: 700;
						}
					}
					a {
						color: #888;
						text-decoration: none;
						text-transform: uppercase;
						display: block;
						padding-bottom: 30px;
						&:focus {outline: 0; }
					}
				}

				.viewtext {
          h3, h4 {
            font-size: 19px;
          }
					@media (max-width: 768px) {
						/*display: block;
						height: auto;
						max-height: 0px;
						overflow: hidden;*/
					}
					visibility: hidden;
					opacity: 0;
					display: none;
					&.open {
						display: block;
						/*@media (max-width: 768px) {
							max-height: 1000px;
			 				-webkit-transition: max-height 0.6s ease-in-out;
			 				-moz-transition: max-height 0.6s ease-in-out;
			 			    -o-transition: max-height 0.6s ease-in-out;
			 			    transition: max-height 0.6s ease-in-out;
						}*/
						visibility: visible;
						opacity: 1;
						-moz-transition: opacity 0.6s ease-in-out;
						-o-transition: opacity 0.6s ease-in-out;
						-webkit-transition: opacity 0.6s ease-in-out;
						transition: opacity 0.6s ease-in-out;

					}
					@media (min-width: 768px) {
						width: 75%;
						padding: 0 20px;
						float: right;
					}
				}
				img {
				    display: block;
				}
				.title {
					margin-top: 0;
          margin-bottom: 30px;
					color: #618846;
          text-transform: uppercase;
          font-weight: 600;
					@media (max-width: 768px) {
						display: none;
					}
					&:before {
						content: "";
						background: url('../images/Housekeeping-50-green.png') no-repeat;
						width: 50px;
						height: 50px;
            margin-right: 20px;
						display: inline-block;
					}
				}
				.field-content {
					@media (min-width: 768px) {
						position: absolute;
						top: 0;
					}
				  	> div {

					}
					.image-container {
						.row();
						ul {
							list-style: none;
							padding: 0;
							li {
								list-style: none;
								.make-sm-column(4);
								margin-bottom: 30px;
							}
						}
					}
				}

			}
		}
	}
}


/* News */

#block-views-news-block-2 {
	padding-top: 80px;
	padding-bottom: 80px;
	.view-news {
    .entity.entity-bean.bean-text-block {
      text-align: center;
      .field.field-name-field-info-text {
        text-transform: uppercase;
        font-size: 13px;
      }
      .field.field-name-title-field {
        h2 {
          margin-top: 0;
          &:after {
            content: "";
						background: url('../images/Google-News-32.png') no-repeat;
						width: 32px;
						height: 32px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 15px;
          }
        }
      }
    }
	  .view-content {
      .row {
        margin-top: 30px;
      }
		  div[class^='col'], div[class*=' col'] {
				> div {
	        max-width:360px;
	        margin-bottom: 30px;
	        margin-left: auto;
	        margin-right: auto;
				  border: 1px solid #dddddd;
				  position: relative;
				  .field.field-name-custom-date-field {
					  position: absolute;
					  top: 10px;
					  left: 0;
					  padding: 10px 20px;
					  background: @brand-primary;
					  color: #fff;
					  text-transform: uppercase;
					  text-align: center;
					  .day {
						  font-size: 24px;
						  line-height: 1;
					  }
					  .month {
						  line-height: 1;
					  }
					}
			  }
			  .field.field-name-title-field {
				  padding-top: 30px;
				  color: #313131;
				  padding-left: 25px;
				  padding-right: 25px;
				  h4 {
					  margin: 0;
					  a {
						  color: #313131;
						  text-decoration: none;
					  }
				  }
			  }
			  .field.field-name-author {
				  padding-left: 25px;
				display: inline-block;
				font-size: 10px;
				text-transform: uppercase;
			  }
			  .field.field-name-field-tags {
			  	display: inline-block;
				font-size: 10px;
				text-transform: uppercase;
				 .field-item {
					 &:before {
						content: "|";
						margin: 0 5px;
						color: #d9d9d9;
					 }
				 }
			  }
			  .field.field-name-body {
				  padding: 15px 25px;
          min-height: 93px;
			  }
			  .field.field-name-node-link {
				  padding-bottom: 15px;
				  text-align: center;
			  }
		  }
	  }
  }
}

.node-type-news-article {
  .group-text {
     max-width: 700px;
  }
  .field {
    &.field-name-post-date {
      color: @brand-primary;
      font-weight: 600;
    }
  }
  @media (min-width: @screen-sm-min) {
    .field {
      &.field-name-field-image {
        .pull-left;
        max-width: 50%;
        margin-bottom: 15px;
      }
      &.field-name-body {
      }
    }
    .group-text {
      width: 50%;
      .pull-left;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

/* Personal */
.view-employees {
  .view-content {
	> h3 {
	  margin-bottom: 30px;
      text-align: center;
	} 
    .row {
      > div[class^='col'], div[class*=' col'] {
		margin-bottom: 30px;
        text-align: center;
        h3 {
          font-size: 21px;
        }
		img {
			margin: auto;
		}
      }
    }
  }
}

/* Gallery */

.row.field.field-name-field-images {
  margin-top: 30px;
  > div[class^='col'], div[class*=' col'] {
    margin-bottom: 30px;
		text-align: center;
		> a {
			display: inline-block;
			margin: 0 auto;
		}
  }
}

/* Social */

#block-block-4 {
  position: absolute;
  @media (min-width: @grid-float-breakpoint) {
    top: -100px;
    right: 0;
  }
  > a {
    border: 1px solid @brand-primary;
    border-radius: 50%;
		height: 30px;
		width: 30px;
		line-height: 30px;
		text-align: center;
    display: inline-block;
  }
}

/* Materialbank */

#block-views-materialbank-block {
  margin-bottom: 30px;
  font-size: 13px;
  .view {
    > .view-content > .views-row {
      margin-bottom: 30px;
      .file {
        a {
          font-weight: bold;
        }
      }
    }
  }
}

#sliding-popup {
  background-color: #81a568;
  color: #fff;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: #fff;
    text-decoration: underline;
    &:hover, &:focus {
      color: #fff;
    }
  }
  button.agree-button, button.decline-button, button.find-more-button {
    .btn;
    .btn-default;
    background-color: transparent;
    border-color: #fff;
    color:#fff;
    &:hover, &:focus, &.active {
      background-color: transparent;
      border-color: #fff;
      color:#fff;
    }
  }
}

.webform-container-inline {
	display:table;
	> * {
		display:table-cell !important;	
	}	
}
